/* eslint-disable no-unused-vars */

import AuthLeftWrapper from "../../components/containers/AuthLeftWrapper";
import { Form, Formik } from "formik";
import FormLabel from "../../components/core/typography/FormLabel";
import InputType from "../../components/core/form-components/InputType";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/core/form-components/Button";
import Paragraph from "../../components/core/typography/Paragraph";
import Logo from "../../assets/images/sitelogo.png";
import { paths } from "../../routes/path";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { ForgotPasswordApi } from "../../service/authService";
import { forgetPasswordValidationSchema } from "../../service/forgetPasswordValidationSchema";

function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const OnSubmit = async (data) => {
    dispatch(showLoader());
    const response = await ForgotPasswordApi({
      email: data.email,
    });
    if (response?.status === 200) {
      navigate(paths?.auth?.login);
    }
    dispatch(hideLoader());
  };

  return (
    <div className="bg-white p-30 min-h-screen">
      <div className="grid grid-cols-12 lg:gap-6 gap-3 h-full">
        <div className="col-span-6 h-full md:block hidden">
          <AuthLeftWrapper />
        </div>
        <div className="md:col-span-6 sm:col-span-8 col-span-12 md:col-start-7 sm:col-start-3">
          <div className="flex items-center flex-col h-full xl:pt-[120px] pt-[100px]">
            <div className="mb-15 block">
              <img src={Logo} alt="logo" width="268px" height="105px" />
            </div>
            <Paragraph text20 className={"mb-2"}>
              Forgot Password
            </Paragraph>
            <Paragraph
              text12
              className={"md:mb-10 mb-5 text-dark-grey text-start"}
            >
              Enter your email address to recover your password
            </Paragraph>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={forgetPasswordValidationSchema}
              onSubmit={OnSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form className="xxl:w-3/4 xl:w-4/5 w-full h-full">
                  <div className="grid grid-cols-12">
                    <div className="col-span-12 mb-6">
                      <FormLabel>Email address</FormLabel>
                      <InputType
                        placeholder="Email"
                        type="text"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={errors.email && touched.email && errors.email}
                      />
                    </div>
                    <div className="col-span-12">
                      <Button
                        primary
                        className={"w-full lg:!py-3"}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Send Code
                      </Button>
                      {/* <Paragraph className={"text-center mt-4"}>
                        Don't have account?{" "}
                        <Link to={paths?.signup}>Register here</Link>.
                      </Paragraph> */}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="col-span-12 mt-4">
              <Paragraph text14 className={"text-dark-grey text-center"}>
                {`© Copyright ${new Date().getFullYear()}. Vrinsoft, All rights reserved.`}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
