export const apiEndPoints = {
  AUTH: {
    LOGIN: "/api/login/",
    FORGOT: "/api/forgot-password/",
    RESET_PASSWORD: "/api/reset-password/",
    REGISTER: "/api/register/",
    REFRESH: "/api/token/refresh/",
    LOGOUT: "/api/logout/",
  },
  USER: {
    ADDROLE: "/api/create-role/",
    ADDUSER: "/api/invite-user/",
    EDITUSER: "/api/users_edit",
    EDITROLE: "/api/role_edit",
    GETUSER: "/api/users_list",
    ROLELIST: "/api/role_list",
  },
  DASHBOARD: {
    INVOICELIST: "/api/invoice-list/",
    DASHBOARD_TOTAL: "/api/analytics/",
    DASHBOARD_COLLECT: "/api/analytics-collected-amounts/",
    ASSIGN_INVOICE: "/api/assign-invoice/",
    INVOICE_DETAILS: "/api/invoice_details",
    GET_FOLLOWUP: "/api/follow_up_data",
    POST_FOLLOWUP: "/api/add_follow_up",
    POST_USER_MSG: "/api/ai_response/",
    USERLIST_DROPDOWN: "/api/users_list_dropdown/",
    POST_ESCALATE_INVOICE: "/api/escalate_account",
    NOTIFICATION_LIST: "/api/notification-listing/",
    ROLE_WISE_USER: "/api/role_wise_users_list/",
  },
};
