import React, { useState } from "react";
import Chart from "react-apexcharts";
import { formatAmount } from "../utils/commonHelper";

const DonutChart = ({ total, seriesData }) => {
  const [Opts] = useState({
    options: {
      chart: {
        redrawOnWindowResize: true,
        id: "category-stocks",
        height: 120,
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      legend: {
        position: "right",
        fontSize: "12px",
        fontWeight: "600",
        fontFamily: "JUST Sans, sans-serif",
        labels: {
          colors: "#202224",
        },
        markers: {
          size: 4,
        },
        formatter: function (seriesName, opts) {
          const value = opts.w.globals.series[opts.seriesIndex];
          const percent = opts.w.globals.seriesPercent[opts.seriesIndex];
          return `<span className='legend-text'>${seriesName}</span><span className"text-dark-grey">${percent[0].toFixed(2)}%</span> <span className"">$${value}</span>`;
        },
      },
      tooltip: {
        theme: "dark",
        style: {
          fontSize: "12px",
          color: "#fff",
          backgroundColor: "transparent",
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: "Total",
                style: {
                  fontSize: "12px",
                  color: "#fff",
                  backgroundColor: "transparent",
                },
                formatter: function (w) {
                  return `$${formatAmount(total) ?? 0}`;
                },
              },
            },
            expandOnClick: false,
          },
        },
      },
      stroke: {
        lineCap: "round",
        show: false,
        width: 0,
      },
      colors: ["#F9CA12", "#12C324", "#48CEFF"],
      fill: {
        type: "solid",
      },
      labels: ["7days", "15days", "30days"],
      responsive: [
        {
          breakpoint: 1920,
          options: {
            chart: {
              height: 120,
            },
            legend: {
              position: "right",
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 280,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: seriesData ?? [0],
  });

  return (
    <Chart
      options={Opts?.options}
      series={seriesData || [0]}
      type="donut"
      className="chart-left"
      height={Opts?.options?.chart?.height}
    />
  );
};

export default DonutChart;
