import SiteLoader from "./components/core/site-loader/SiteLoader";
import ToastNotification from "./components/core/toast-notification/ToastNotification";
import AppRoutes from "./routes/AppRoutes";

function App() {
  return (
    <>
      <SiteLoader />
      <AppRoutes />
      <ToastNotification />
    </>
  );
}

export default App;
