// src/routes/routes.js
import ForgetPassword from "../pages/authentication/ForgetPassword";
import Login from "../pages/authentication/Login";
import ResetPassword from "../pages/authentication/ResetPassword";
import ClientAccount from "../pages/client_account/ClientAccount";
import CommonElement from "../pages/CommonElement";
import Dashboard from "../pages/Dashboard";
import UserManagement from "../pages/UserManagement";
import Unauthorized from "../pages/unauthorized/Unauthorized";
import { paths } from "./path";

export const AuthRoutes = [
  { path: paths.auth.login, name: "Login", element: Login },
  {
    path: paths.auth.forget_password,
    name: "Forget Password",
    element: ForgetPassword,
  },
  {
    path: paths.auth.reset_password,
    name: "Reset Password",
    element: ResetPassword,
  },
];

export const ProtectedRoutes = [
  {
    path: paths.web.dashboard,
    name: "Dashboard",
    element: Dashboard,
    permissions: [],
  },
  {
    path: paths.web.usermanagement,
    name: "User Management",
    element: UserManagement,
    permissions: ["is_admin_access"],
  },
  {
    path: `${paths.web.accountDetails}/:id`,
    name: "Account Details",
    element: ClientAccount,
    permissions: [],
  },
  {
    path: paths.web.commonelements,
    name: "Common Components",
    element: CommonElement,
    permissions: [],
  },
  {
    path: paths.web.unauthorized,
    name: "Unauthorized",
    element: Unauthorized,
    permissions: [],
  },
];
