export const capitalizeFunction = (str) => {
  return typeof str === "string"
    ? str.charAt(0).toUpperCase() + str.slice(1)
    : str;
};

export const formatAmount = (amount) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export const formatDate = (newDate) => {
  const date = new Date(newDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
