/* eslint-disable react/prop-types */
import { useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";

function InputType({ className, type, tableSearch, value, ...props }) {
  const [isVisible, setVisible] = useState(false);
  const toggle = () => {
    setVisible(!isVisible);
  };
  return (
    <div className="form-control relative">
      <input
        {...props}
        value={value}
        type={type === "password" ? (isVisible ? "text" : "password") : type}
        className={`${
          className ? className : ""
        } rounded border border-light-grey-200 pt-[11px] pb-[10px] text-[16px] leading-[21px] font-normal w-full focus:outline-0 placeholder:text-light-grey text-site-black focus-within:outline-none ${
          type === "search"
            ? "bg-search-icon bg-no-repeat bg-[left_12px_top_10px] ps-10 pe-4"
            : "px-4"
        } ${tableSearch ? "pt-[7px] pb-[8px] bg-[left_12px_top_7px]" : ""}
          ${type === "password" && "pr-[40px]"}`}
      />
      {type === "password" ? (
        <span
          className="absolute right-4 top-[14px] cursor-pointer"
          onClick={toggle}
        >
          {isVisible ? (
            <IoEyeOff size={16} color="#9C9C9C" />
          ) : (
            <IoEye size={16} color="#9C9C9C" />
          )}
        </span>
      ) : (
        ""
      )}
      <span className="text-site-red text-sm font-medium">{props.error}</span>
    </div>
  );
}

export default InputType;
