import React, { useEffect, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { formatDate } from "../utils/commonHelper";

const DatePicker = ({ dateRange, setDateRange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    if (dates.startDate === null || dates.endDate === null) {
      setStartDate(null);
      setEndDate(null);
    } else {
      const start = formatDate(dates.startDate);
      const end = formatDate(dates.endDate);
      setStartDate(start);
      setEndDate(end);
    }
  };

  useEffect(() => {
    setDateRange({ start: startDate, end: endDate });
  }, [startDate, endDate]);

  return (
    <>
      <Datepicker
        inputClassName="rounded border border-light-grey-200 py-[8px] pl-2 text-[16px] leading-[21px] font-normal w-[250px] focus:outline-0 placeholder:text-light-grey"
        useRange={false}
        separator="to"
        value={{ startDate: dateRange?.start, endDate: dateRange?.end }}
        onChange={(newValue) => onChange(newValue)}
        dateFormat="YYYY-MM-DD"
        placeholder="Select Date"
      />
    </>
  );
};

export default DatePicker;
