import { Link } from "react-router-dom";

function breadcrumbs({ children, className, id, ...props }) {
  return (
    <ul className="flex items-center mb-2">
      <li className="pr-3 mr-3 relative text-[14px] leading-[18px] font-normal before:absolute before:right-0 before:top-0 before:content-['/'] before:text-light-grey">
        <Link
          className="text-[14px] leading-[18px] font-normal text-light-grey hover:text-primary-blue"
          to="/dashboard"
        >
          Dashboard
        </Link>
      </li>
      <li>
        <span className="text-[14px] leading-[18px] font-normal text-primary-blue">
          {id}
        </span>
      </li>
    </ul>
  );
}

export default breadcrumbs;
