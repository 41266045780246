 

function HeadingFour({ children, className }) {
  return (
    <h4
      className={`text-[18px] leading-[24px] font-normal ${
        className ? className : ""
      }`}
    >
      {children}
    </h4>
  );
}

export default HeadingFour;
