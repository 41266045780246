export const paths = {
    auth: {
      login: '/',
      loginUser: '/login',
      addNewPassword: '/add-new-password',
      forgotPassword: '/forget-password',
      otp: '/otp',
      changePassword: '/change-password',
    },
    main:{
      dashboard:"/",
      userList:"/users"
    }
}