import classNames from "classnames";
 

function Checkbox({ name, id, children, className, w16, ...rest }) {
  const classes = classNames(
    "before:content[''] peer relative cursor-pointer appearance-none rounded-[4px] border border-light-grey transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-4 before:w-4 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primary-blue checked:bg-primary-blue checked:before:bg-primary-blue",
    {
      "w-[16px] h-[16px]": w16,
    }
  );
  return (
    <div className={`inline-flex items-center gap-2 ${className}`}>
      <label
        className="relative flex items-center rounded-full cursor-pointer"
        htmlFor={id}
      >
        <input
          type="checkbox"
          className={`${classes}`}
          id={id}
          name={name}
          {...rest}
        />
        <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
          <svg
            width="9"
            height="7"
            viewBox="0 0 9 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 3.44444L2.98352 5.42796C3.14486 5.5893 3.22553 5.66997 3.31855 5.7002C3.40037 5.72678 3.48852 5.72678 3.57034 5.7002C3.66336 5.66997 3.74403 5.5893 3.90537 5.42796L8.33333 1"
              stroke="white"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </label>
      <label
        className="text-site-black cursor-pointer text-[14px] font-normal leading-[18px] select-none"
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  );
}

export default Checkbox;
