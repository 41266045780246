import { useEffect, useRef, useState } from "react";
import Sidebar from "./containers/Sidebar";
import Header from "./containers/Header";
import { Outlet } from "react-router-dom";

function Wrapper() {
  const [shadow, setShadow] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current.scrollTop > 0) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };

    const currentDiv = scrollRef.current;
    currentDiv.addEventListener("scroll", handleScroll);

    return () => {
      currentDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-wrap items-stretch">
      <div
        className={`xl:w-[85px] w-[70px] relative transition-[width] duration-500 ease-in-out`}
      >
        <Sidebar />
      </div>
      <div
        className={`xl:w-[calc(100%-85px)] w-[calc(100%-70px)] transition-[width] duration-500 ease-in-out`}
      >
        <div
          className="bg-light-grey-bg h-screen overflow-y-auto relative"
          ref={scrollRef}
        >
          <div
            className={`sticky top-0 bg-white z-50 transition-all duration-300 ease-in-out ${
              shadow ? "shadow-cardShadow px-6 py-3" : "py-3 px-12 lg:px-6"
            }`}
          >
            <Header />
          </div>
          <div className="xl:py-6 xl:px-6 py-4 px-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wrapper;
