 

function HeadingTwo({ children, className }) {
  return (
    <h2
      className={`md:text-[28px] md:leading-[36px] text-[24px] leading-[30px] font-bold ${
        className ? className : ""
      }`}
    >
      {children}
    </h2>
  );
}

export default HeadingTwo;
