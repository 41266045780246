export const paths = {
  auth: {
    login: "/",
    reset_password: "/reset-password",
    forget_password: "/forget-password",
  },
  web: {
    commonelements: "/commonelements",
    dashboard: "/dashboard",
    usermanagement: "/usermanagement",
    accountDetails: "/account",
    unauthorized: "/unauthorized",
  },
};
