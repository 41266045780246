import { apiEndPoints } from "../apiEndPoints";
import { axiosGet, axiosPost } from "../axios/axiosMiddleware";

export const GetAllInvoiceList = (queryString) => {
  return axiosGet(`${apiEndPoints?.DASHBOARD?.INVOICELIST}${queryString}`);
};

export const GetAnalytics = (queryString) => {
  return axiosGet(`${apiEndPoints?.DASHBOARD?.DASHBOARD_TOTAL}${queryString}`);
};

export const GetCollectedAmount = () => {
  return axiosGet(`${apiEndPoints?.DASHBOARD?.DASHBOARD_COLLECT}`);
};

export const Assign = (data) => {
  return axiosPost(`${apiEndPoints?.DASHBOARD?.ASSIGN_INVOICE}`, data);
};

export const InvoiceDetail = (id) => {
  return axiosGet(`${apiEndPoints?.DASHBOARD?.INVOICE_DETAILS}/${id}`);
};

export const FollowUpHistory = (id) => {
  return axiosGet(`${apiEndPoints?.DASHBOARD?.GET_FOLLOWUP}/${id}`);
};

export const SendFollowUp = (id, data) => {
  return axiosPost(`${apiEndPoints?.DASHBOARD?.POST_FOLLOWUP}/${id}/`, data);
};

export const SendUserMsg = (queryString, data) => {
  return axiosPost(
    `${apiEndPoints?.DASHBOARD?.POST_USER_MSG}${queryString}`,
    data
  );
};

export const GetAllUsers = (queryString) => {
  return axiosGet(
    `${apiEndPoints?.DASHBOARD?.USERLIST_DROPDOWN}${queryString}`
  );
};

export const SendEscalateInvoice = (id, data) => {
  return axiosPost(
    `${apiEndPoints?.DASHBOARD?.POST_ESCALATE_INVOICE}/${id}/`,
    data
  );
};

export const GetNotificationList = () => {
  return axiosGet(`${apiEndPoints?.DASHBOARD?.NOTIFICATION_LIST}`);
};

export const GetRoleUserList = () => {
  return axiosGet(`${apiEndPoints?.DASHBOARD?.ROLE_WISE_USER}`);
};
