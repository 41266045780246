import { PiArrowUpBold } from "react-icons/pi";
import Paragraph from "./core/typography/Paragraph";
import { IoMdTrendingDown, IoMdTrendingUp } from "react-icons/io";

function DashboardStatCard({
  children,
  className,
  increased,
  currency,
  ...props
}) {
  return (
    <div
      className={`flex flex-col justify-between gap-4 xxl:p-5 p-4 rounded-lg relative h-full bg-white `}
    >
      <div className="flex items-start justify-between gap-3">
        <div>
          <Paragraph text16 className={"text-dark-grey xl:mb-4 mb-3"}>
            {props.statName}
          </Paragraph>
          <Paragraph text20 className={"text-site-black"}>
            {`${currency} ${props.statValue}`}
          </Paragraph>
        </div>
        <div
          className={`${
            className ? className : ""
          } max-[1366px]:rounded-[12px] rounded-[23px] max-[1366px]:w-[40px] max-[1366px]:h-[40px] w-[60px] h-[60px] inline-flex items-center justify-center flex-shrink-0 `}
        >
          {props.icon}
        </div>
      </div>
      {/* <div className="inline-flex items-center gap-2">
        {increased ? (
          <IoMdTrendingUp fontSize={"24px"} className={"text-light-green"} />
        ) : (
          <IoMdTrendingDown fontSize={"24px"} className={"text-light-red"} />
        )}
        {props.increasedValue && (
          <Paragraph text16 className="text-dark-grey">
            <span
              className={` inline-block mr-1 ${
                increased ? "text-light-green" : "text-light-red"
              } `}
            >
              {props.increasedValue}
            </span>
            {props.increasedContent}
          </Paragraph>
        )}
      </div> */}
    </div>
  );
}

export default DashboardStatCard;
