 

function RadioButton({ name, id, children, className, ...rest }) {
  return (
    <div className={`inline-flex items-center gap-2 ${className}`}>
      <label
        className="relative flex items-center rounded-full cursor-pointer"
        htmlFor={id}
      >
        <input
          type="radio"
          className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-light-grey transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-3 before:w-3 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:bg-primary-blue checked:border-primary-blue "
          id={id}
          name={name}
          {...rest}
        />
        <span className="absolute block w-1.5 h-1.5 rounded-full peer-checked:bg-white text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"></span>
      </label>
      <label
        className={`text-site-black cursor-pointer text-[16px] font-normal leading-[21px] select-none ${className}`}
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  );
}

export default RadioButton;
