// src/routes/AppRoutes.jsx
import { Route, Routes } from "react-router-dom";
import { AuthRoutes, ProtectedRoutes } from "./routes";
import GuestRoute from "./GuestRoute";
import SecurityCheck from "./SecurityCheck";
import Wrapper from "../components/Wrapper";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "../pages/not-found/NotFound";

const AppRoutes = () => {
  return (
    <Routes>
      {/* Protected routes */}
      <Route element={<SecurityCheck />}>
        <Route element={<Wrapper />}>
          {ProtectedRoutes.map((route, id) => (
            <Route
              key={id}
              path={route.path}
              element={
                <ProtectedRoute
                  element={route.element}
                  permissions={route.permissions}
                />
              }
            />
          ))}
        </Route>
      </Route>

      {/* Auth routes */}
      {AuthRoutes.map((route, id) => (
        <Route
          key={id}
          path={route.path}
          element={
            <GuestRoute>
              <route.element />
            </GuestRoute>
          }
        />
      ))}

      {/* Fallback routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
