 

function HeadingFive({ children, className }) {
  return (
    <h5
      className={`text-[16px] leading-[21px] font-semibold ${
        className ? className : ""
      }`}
    >
      {children}
    </h5>
  );
}

export default HeadingFive;
