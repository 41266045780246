import React, { useState } from "react";
import Paragraph from "../components/core/typography/Paragraph";
import HeadingOne from "../components/core/typography/HeadingOne";
import HeadingTwo from "../components/core/typography/HeadingTwo";
import HeadingThree from "../components/core/typography/HeadingThree";
import HeadingFour from "../components/core/typography/HeadingFour";
import HeadingFive from "../components/core/typography/HeadingFive";
import HeadingSix from "../components/core/typography/HeadingSix";
import FormLabel from "../components/core/typography/FormLabel";
import Button from "../components/core/form-components/Button";
import { FiPlus } from "react-icons/fi";
import { MdOutlineShoppingCart } from "react-icons/md";
import InputType from "../components/core/form-components/InputType";
import Checkbox from "../components/core/form-components/Checkbox";
import RadioButton from "../components/core/form-components/RadioButton";
import SelectType from "../components/core/form-components/SelectType";
import Accordion, { AccordionItem } from "../components/core/Accordion";
import WhiteCard from "../components/WhiteCard";
import { Tab, Tabs } from "../components/core/Tabs";
import Modal from "../components/core/Modal";
import DashboardStatCard from "../components/DashboardStatCard";
import { HiMiniInboxArrowDown } from "react-icons/hi2";
import ReactPaginate from "react-paginate";
import { RxArrowLeft, RxArrowRight } from "react-icons/rx";

function CommonElement(props) {
  const { handlePageClick, itemOffset } = props;
  const [option, setOption] = useState([
    {
      label: "Option 1",
      value: "opt1",
    },
    {
      label: "Option 2",
      value: "opt2",
    },
    {
      label: "Option 3",
      value: "opt3",
    },
    {
      label: "Option 4",
      value: "opt4",
    },
    {
      label: "Very High",
      value: "opt5",
    },
  ]);
  const handleChangeSelect = (e) => {};
  const [open, setOpen] = useState(false);
  const dataItem = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className="container py-15">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-6">
          <HeadingOne>Heading One</HeadingOne>
          <HeadingTwo>Heading Two</HeadingTwo>
          <HeadingThree>Heading Three</HeadingThree>
          <HeadingFour>Heading Four</HeadingFour>
          <HeadingFive>Heading Five</HeadingFive>
          <HeadingSix>Heading Six</HeadingSix>
        </div>
        <div className="col-span-6">
          <Paragraph text32 className={"text-primary-blue"}>
            Hi! This is{" "}
            <span className="font-bold">Extra Large Paragraph(32px)</span>. Here
            we can write dummy text. Thank you!
          </Paragraph>
          <Paragraph text28>
            Hi! This is <span className="font-bold">Large Paragraph(28px)</span>
            . Here we can write dummy text. Thank you!
          </Paragraph>
          <Paragraph text20>
            Hi! This is{" "}
            <span className="font-bold">Medium Paragraph(20px)</span>. Here we
            can write dummy text. Thank you!
          </Paragraph>
          <Paragraph text18>
            Hi! This is <span className="font-bold">Base Paragraph(18px)</span>.
            Here we can write dummy text. Thank you!
          </Paragraph>
          <Paragraph text16>
            Hi! This is{" "}
            <span className="font-bold">Normal Paragraph(16px)</span>. Here we
            can write dummy text. Thank you!
          </Paragraph>
          <Paragraph text14>
            Hi! This is <span className="font-bold">Small Paragraph(14px)</span>
            . Here we can write dummy text. Thank you!
          </Paragraph>
          <Paragraph text14Semibold>
            Hi! This is{" "}
            <span className="font-bold">Small Paragraph SemiBold(14px)</span>.
            Here we can write dummy text. Thank you!
          </Paragraph>
          <Paragraph text12>
            Hi! This is{" "}
            <span className="font-bold">Extra Small Paragraph(12px)</span>. Here
            we can write dummy text. Thank you!
          </Paragraph>
          <FormLabel htmlFor="test" className={"test"}>
            This is form Label
          </FormLabel>
        </div>
        <div className="col-span-2">
          <Button primary onClick={() => setOpen(true)}>
            Solid Button
          </Button>
        </div>
        <div className="col-span-2">
          <Button secondary>Line Button</Button>
        </div>
        <div className="col-span-2">
          <Button disabled>Disabled Button</Button>
        </div>
        <div className="col-span-2">
          <Button primary>
            <FiPlus fontSize={"18px"} />
            Button with Icon
          </Button>
        </div>
        <div className="col-span-2">
          <Button secondary>
            <FiPlus fontSize={"18px"} />
            Button with Icon
          </Button>
        </div>
        <div className="col-span-2">
          <Button onlyIcon primary>
            <MdOutlineShoppingCart fontSize={"18px"} />
          </Button>
        </div>
        <div className="col-span-4">
          <InputType type="text" placeholder="Text Field" />
        </div>
        <div className="col-span-4">
          <InputType type="password" placeholder="Password Field" />
        </div>
        <div className="col-span-4">
          <InputType type="search" placeholder="Search Field" />
        </div>
        <div className="col-span-3">
          <FormLabel>Checkbox Group 1</FormLabel>
          <div className="flex items-center gap-6">
            <Checkbox w16 name={"checkbox"} id={"checkbox1"}>
              Checkbox1
            </Checkbox>
            <Checkbox w16 name="checkbox" id={"checkbox2"}>
              Checkbox2
            </Checkbox>
          </div>
        </div>
        <div className="col-span-3">
          <FormLabel>Radio Group 1</FormLabel>
          <div className="flex items-center gap-6">
            <RadioButton name={"radio"} id={"radio1"}>
              Radio1
            </RadioButton>
            <RadioButton name="radio" id={"radio2"}>
              Radio2
            </RadioButton>
          </div>
        </div>
        <div className="col-span-4">
          <SelectType
            options={option}
            fullWidth
            placeHolder="Please select..."
            onChange={(e) => handleChangeSelect(e)}
            isSearchable
            isMulti
          />
        </div>
        <div className="col-span-4">
          <SelectType
            options={option}
            fullWidth
            placeHolder="Please select..."
            onChange={(e) => handleChangeSelect(e)}
          />
        </div>
        <div className="col-span-4">
          <SelectType
            options={option}
            fullWidth
            placeHolder="Priority"
            onChange={(e) => handleChangeSelect(e)}
            tableSelect
          />
        </div>
        <div className="col-span-6">
          <Accordion className="max-w-full">
            <AccordionItem value="1" trigger="Accordion Item 1">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos
              maiores reprehenderit, quasi tenetur quaerat hic aperiam mollitia
              eaque id ipsam.
            </AccordionItem>
            <AccordionItem value="2" trigger="Accordion Item 2">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos
              maiores reprehenderit, quasi tenetur quaerat hic aperiam mollitia
              eaque id ipsam.
            </AccordionItem>
          </Accordion>
        </div>
        <div className="col-span-6">
          <WhiteCard>
            <Tabs>
              <Tab label="Tab 1">
                <div className="py-4">
                  <h2 className="text-[16px] leading-[21px] font-semibold mb-2">
                    Tab 1 Content
                  </h2>
                  <p className="text-dark-grey">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maxime mollitia, molestiae quas vel sint commodi repudiandae
                    consequuntur voluptatum laborum numquam blanditiis harum
                    quisquam eius sed odit fugiat iusto fuga praesentium optio,
                    eaque rerum! Provident similique accusantium nemo autem.
                    Veritatis obcaecati tenetur iure eius earum ut molestias
                    architecto voluptate aliquam nihil, eveniet aliquid culpa
                    officia aut! Impedit sit sunt quaerat, odit, tenetur error,
                    harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
                    quia. Quo neque error repudiandae fuga? Ipsa laudantium
                    molestias eos sapiente officiis modi at sunt excepturi
                    expedita sint? Sed quibusdam recusandae alias error harum
                    maxime adipisci amet laborum.
                  </p>
                </div>
              </Tab>
              <Tab label="Tab 2">
                <div className="py-4">
                  <h2 className="text-[16px] leading-[21px] font-semibold mb-2">
                    Tab 2 Content
                  </h2>
                  <p className="text-dark-grey">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maxime mollitia, molestiae quas vel sint commodi repudiandae
                    consequuntur voluptatum laborum numquam blanditiis harum
                    quisquam eius sed odit fugiat iusto fuga praesentium optio,
                    eaque rerum! Provident similique accusantium nemo autem.
                    Veritatis obcaecati tenetur iure eius earum ut molestias
                    architecto voluptate aliquam nihil, eveniet aliquid culpa
                    officia aut! Impedit sit sunt quaerat, odit, tenetur error,
                    harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
                    quia. Quo neque error repudiandae fuga? Ipsa laudantium
                    molestias eos sapiente officiis modi at sunt excepturi
                    expedita sint? Sed quibusdam recusandae alias error harum
                    maxime adipisci amet laborum.
                  </p>
                </div>
              </Tab>
              <Tab label="Tab 3">
                <div className="py-4">
                  <h2 className="text-[16px] leading-[21px] font-semibold mb-2">
                    Tab 3 Content
                  </h2>
                  <p className="text-dark-grey">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maxime mollitia, molestiae quas vel sint commodi repudiandae
                    consequuntur voluptatum laborum numquam blanditiis harum
                    quisquam eius sed odit fugiat iusto fuga praesentium optio,
                    eaque rerum! Provident similique accusantium nemo autem.
                    Veritatis obcaecati tenetur iure eius earum ut molestias
                    architecto voluptate aliquam nihil, eveniet aliquid culpa
                    officia aut! Impedit sit sunt quaerat, odit, tenetur error,
                    harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
                    quia. Quo neque error repudiandae fuga? Ipsa laudantium
                    molestias eos sapiente officiis modi at sunt excepturi
                    expedita sint? Sed quibusdam recusandae alias error harum
                    maxime adipisci amet laborum.
                  </p>
                </div>
              </Tab>
            </Tabs>
          </WhiteCard>
        </div>
        <div className="col-span-3">
          <DashboardStatCard
            increased
            className={"bg-[#D9F7E7]"}
            icon={
              <HiMiniInboxArrowDown
                fontSize={"30px"}
                className={"text-[#4AD991]"}
              />
            }
            increasedValue={"8.5%"}
            increasedContent={"Up from last month"}
            statValue={"$1,40,689"}
            statName={"Total Receivable"}
          />
        </div>
        <div className="col-span-3">
          {dataItem?.length > 0 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel={<RxArrowRight fontSize={"20px"} />}
              onPageChange={handlePageClick}
              pageCount={2}
              previousLabel={<RxArrowLeft fontSize={"18px"} />}
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
              pageClassName={"pageItem"}
              forcePage={itemOffset - 1}
            />
          )}
        </div>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          headingText={"This Is Heading"}
        >
          <Paragraph text18>
            Hi this is modal/popup whatever you would like to say!
          </Paragraph>
        </Modal>
      </div>
    </div>
  );
}

export default CommonElement;
