export const Priority = [
  {
    label: "Low",
    value: "1",
    title: "priority",
  },
  {
    label: "Medium",
    value: "2",
    title: "priority",
  },
  {
    label: "High",
    value: "3",
    title: "priority",
  },
  {
    label: "Very High",
    value: "4",
    title: "priority",
  },
];

export const Overdue = [
  {
    label: "0-30",
    value: "0-30",
    title: "overdue_status",
  },
  {
    label: "30-60",
    value: "30-60",
    title: "overdue_status",
  },
  {
    label: "60-90",
    value: "60-90",
    title: "overdue_status",
  },
  {
    label: "above 90",
    value: "above 90",
    title: "overdue_status",
  },
];

export const Followup_Platform = {
  Email: "EMAIL",
  WhatsApp: "WHATSAPP",
  call: "CALL",
  Other: "OTHER",
};

export const Period = [
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Quaterly",
    value: "quarterly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];
