/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Paragraph from "../components/core/typography/Paragraph";
import { Link } from "react-router-dom";
import InputType from "../components/core/form-components/InputType";
import SelectType from "../components/core/form-components/SelectType";
import DashboardStatCard from "../components/DashboardStatCard";
import { HiMiniInboxArrowDown } from "react-icons/hi2";
import { RxCountdownTimer } from "react-icons/rx";
import TableLayout from "../components/TableLayout";
import Button from "../components/core/form-components/Button";
import FormLabel from "../components/core/typography/FormLabel";
import Modal from "../components/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../redux/slices/siteLoaderSlice";
import {
  Assign,
  GetAllInvoiceList,
  GetCollectedAmount,
  GetAnalytics,
  GetRoleUserList,
} from "../service/dashboardService";
import { Overdue, Period, Priority } from "../constants/constants";
import { userTypeSelector } from "../redux/slices/userSlice";
import { formatAmount, formatDate } from "../utils/commonHelper";
import DonutChart from "../components/DonutChart";
import DatePicker from "../components/DatePicker";
import { IoClose } from "react-icons/io5";

const Dashboard = () => {
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const userType = useSelector(userTypeSelector);
  const [openModal, setOpen] = useState(false);
  const [orderby, setOrderby] = useState("");
  const [order, setOrder] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [analytics, setAnalytics] = useState("");
  const [priority, setpriority] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [overdue, setOverdue] = useState("");
  const [usdChartData, setUsdChartData] = useState([]);
  const [audChartData, setAudChartData] = useState([]);
  const [chartPercent, setChartPercent] = useState({ usd: 0, aud: 0 });
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [roleUserMap, setRoleUserMap] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [duration, setDuration] = useState("monthly");

  useEffect(() => {
    getInvoiceList(itemOffset / itemsPerPage + 1);
  }, [orderby, order, itemOffset]);

  useEffect(() => {
    getInvoiceList(1);
  }, [
    orderby,
    order,
    searchTerm,
    priority,
    overdue,
    dateRange?.start,
    dateRange?.end,
    selectedIds,
  ]);

  useEffect(() => {
    dispatch(showLoader());
    fetchCollectedAmount();
    fetchAnalytics();
    fetchRoleUserList();
    dispatch(hideLoader());
  }, []);

  useEffect(() => {
    dispatch(showLoader());
    fetchAnalytics();
    dispatch(hideLoader());
  }, [duration]);

  const fetchCollectedAmount = async () => {
    const res = await GetCollectedAmount();
    let data = res?.data?.data;
    if (data) {
      setChartPercent({ usd: data?.total_usd, aud: data?.total_aud });
      setUsdChartData([
        Number(data?.last_7_days_amount_usd),
        Number(data?.last_15_days_amount_usd),
        Number(data?.last_30_days_amount_usd),
      ]);
      setAudChartData([
        Number(data?.last_7_days_amount_aud),
        Number(data?.last_15_days_amount_aud),
        Number(data?.last_30_days_amount_aud),
      ]);
    } else {
      setUsdChartData([0, 0, 0]);
      setAudChartData([0, 0, 0]);
    }
  };

  const fetchRoleUserList = async () => {
    const res = await GetRoleUserList();
    let data = res?.data?.data;
    if (data) {
      setRoleUserMap(data);
    }
  };

  const fetchAnalytics = async () => {
    let queryString = "";
    if (duration) {
      queryString += `?time_period=${duration}`;
    }
    const res = await GetAnalytics(queryString);
    let data = res?.data?.data;
    if (data) {
      setAnalytics(data);
    }
  };

  const handlePageClick = (event) => {
    setItemOffset(event.selected * itemsPerPage);
  };

  const handleSorting = (key) => {
    setOrderby(key);
    setOrder(!order);
    setItemOffset(0);
  };

  const resetFilter = () => {
    setOverdue("");
    setSearchTerm("");
    setpriority("");
    setDateRange({ start: null, end: null });
  };

  const getInvoiceList = async (page) => {
    dispatch(showLoader());
    try {
      let queryString = `?page=${page}`;
      if (orderby) {
        const orderDirection = order ? "" : "-";
        queryString += `&ordering=${orderDirection}${orderby}`;
      }
      if (searchTerm) {
        const searchTerm1 = searchTerm.trim().toLowerCase();
        queryString += `&search=${searchTerm1}`;
      }
      if (priority) {
        queryString += `&priority=${priority}`;
      }
      if (overdue) {
        queryString += `&overdue_status=${overdue}`;
      }
      if (dateRange?.start && dateRange?.end) {
        queryString += `&date_from=${formatDate(dateRange?.start)}&date_to=${formatDate(dateRange?.end)}`;
      }
      const response = await GetAllInvoiceList(queryString);
      if (response?.status === 200) {
        setCurrentItems(response?.data?.data?.results || []);
        setPageCount(Math.ceil(response.data.data.count / itemsPerPage));
      }
    } catch (error) {
      console.error("Error fetching invoice data:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const tableHeader = [
    {
      key: "Invoice Id",
      value: "invoice_number",
      sorting: true,
      sortkey: "invoice_number",
      textLeft: true,
      cell: ({ invoice_number, id }) => {
        return (
          <div>
            <Link to={`/account/${id}`}>{invoice_number}</Link>
          </div>
        );
      },
    },
    {
      key: "Project",
      value: "project_name",
      sorting: true,
      sortkey: "project_name",
      textLeft: true,
      cell: ({ project_name, id }) => {
        return (
          <div>
            <Link to={`/account/${id}`}>{project_name}</Link>
          </div>
        );
      },
    },
    {
      key: "Client Name",
      value: "customer_name",
      sorting: true,
      sortkey: "customer_name",
      textLeft: true,
      cell: ({ customer_name, id }) => {
        return (
          <div>
            <Link to={`/account/${id}`}>{customer_name}</Link>
          </div>
        );
      },
    },
    {
      key: "Invoice Date",
      value: "invoice_date",
      sorting: true,
      sortkey: "invoice_date",
      textLeft: true,
    },
    {
      key: "Due Date",
      value: "due_date_plus_7",
      sorting: true,
      sortkey: "due_date_plus_7",
      textLeft: true,
    },
    {
      key: "Overdue",
      value: "overdue_status",
      sorting: true,
      sortkey: "overdue_status  ",
      textLeft: true,
    },
    {
      key: "Due Amount",
      value: "amount_overdue",
      sorting: true,
      sortkey: "amount_overdue",
      textLeft: true,
      cell: ({ amount_overdue, currency_type }) => {
        return <div>{`${currency_type} ${amount_overdue}`}</div>;
      },
    },
    {
      key: "Follow Ups",
      value: "followup_count",
      sorting: true,
      sortkey: "followup_count",
      textLeft: true,
      cell: ({ followup_count }) => {
        return <div>{`${followup_count}`}</div>;
      },
    },
    {
      key: "Priority",
      value: "priority_label",
      sorting: true,
      sortkey: "priority",
      textLeft: true,
      // cell: ({ priority_label }) => {
      //   return (
      //     <SelectType
      //       options={priority}
      //       fullWidth
      //       placeHolder={priority_label}
      //       onChange={() => {}}
      //       tableSelect
      //     />
      //   );
      // },
    },
  ];

  const handleBulk = (data) => {
    const updatedSelectedIds = selectedIds?.includes(data.id)
      ? selectedIds?.filter((id) => id !== data.id)
      : [...selectedIds, data.id];

    setSelectedIds(updatedSelectedIds);
  };

  const handleAllBulk = (data) => {
    if (selectedIds?.length === data?.length) {
      setSelectedIds([]);
    } else {
      const allIds = data?.map((item) => item.id);
      setSelectedIds(allIds);
    }
  };

  async function handleAsign() {
    dispatch(showLoader());
    const data = {
      invoice_ids: selectedIds,
      users: Object.values(selectedValues),
    };
    try {
      const response = await Assign(data);
      if (response.status === 200) {
        setSelectedIds([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
      setSelectedIds([]);
      setOpen(false);
    }
  }

  const handleSelectChange = (key, value) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div className="flex items-center justify-between pb-4">
            <Paragraph text32>Dashboard</Paragraph>
            <SelectType
              options={Period}
              placeHolder="Monthly"
              onChange={(e) => setDuration(e.value)}
              tableSelect
              className="min-w-[118px]"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 sm:gap-4 gap-3">
        {userType?.is_amount_visible && (
          <>
            <div className="col-span-6">
              <div className="bg-[#EAEEFF] py-4 xxl:px-30 px-4 rounded-lg">
                <div className="grid grid-cols-12 sm:gap-4 gap-3">
                  <div className="xl:col-span-6 col-span-12">
                    <DashboardStatCard
                      className={"bg-[#D9F7E7]"}
                      statName={"Total Receivable"}
                      currency="USD"
                      statValue={`$${formatAmount(analytics?.total_recivable_all_usd ?? 0)}`}
                      icon={
                        <HiMiniInboxArrowDown
                          fontSize={"30px"}
                          className={"text-[#4AD991]"}
                        />
                      }
                    />
                  </div>
                  <div className="xl:col-span-6 col-span-12">
                    <DashboardStatCard
                      className={"bg-[#FFDEE5]"}
                      statName={"Total Overdue"}
                      currency="USD"
                      statValue={`$${formatAmount(analytics?.total_amount_overdue_usd ?? 0)}`}
                      icon={
                        <RxCountdownTimer
                          fontSize={"30px"}
                          className={"text-[#F93C65]"}
                        />
                      }
                    />
                  </div>
                  <div className="col-span-12">
                    <div className="h-full bg-white rounded-lg p-5">
                      <Paragraph
                        text20
                        className="font-bold mb-5 text-site-black"
                      >
                        Collected Amounts
                      </Paragraph>
                      {chartPercent?.usd && usdChartData?.length && (
                        <DonutChart
                          total={chartPercent?.usd}
                          seriesData={usdChartData}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-6">
              <div className="bg-[#FFEAEA] py-4 xl:px-30 px-4 rounded-lg">
                <div className="grid grid-cols-12 sm:gap-4 gap-3">
                  <div className="xl:col-span-6 col-span-12">
                    <DashboardStatCard
                      className={"bg-[#D9F7E7]"}
                      statName={"Total Receivable"}
                      currency="AUD"
                      statValue={`$${formatAmount(analytics?.total_recivable_all_aud ?? 0)}`}
                      icon={
                        <HiMiniInboxArrowDown
                          fontSize={"30px"}
                          className={"text-[#4AD991]"}
                        />
                      }
                    />
                  </div>
                  <div className="xl:col-span-6 col-span-12">
                    <DashboardStatCard
                      className={"bg-[#FFDEE5]"}
                      statName={"Total Overdue"}
                      currency="AUD"
                      statValue={`$${formatAmount(analytics?.total_amount_overdue_aud ?? 0)}`}
                      icon={
                        <RxCountdownTimer
                          fontSize={"30px"}
                          className={"text-[#F93C65]"}
                        />
                      }
                    />
                  </div>
                  <div className="col-span-12">
                    <div className="h-full bg-white rounded-lg p-5">
                      <Paragraph
                        text20
                        className="font-bold mb-5 text-site-black"
                      >
                        Collected Amounts
                      </Paragraph>
                      {chartPercent?.aud && audChartData?.length && (
                        <DonutChart
                          total={chartPercent?.aud}
                          seriesData={audChartData}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="col-span-12 pb-12">
          <div className="xl:p-[30px] p-[24px] rounded-lg bg-white">
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="xl:col-span-3 col-span-12">
                <Paragraph
                  text20
                  className={`font-bold flex items-center lg:gap-5 md:gap-4 gap-3`}
                >
                  Pending Accounts
                  {selectedIds.length !== 0 && (
                    <Link
                      onClick={() => setOpen(true)}
                      className={`text-primary-blue hover:text-site-black underline text-[16px] leading-[21px] font-semibold transition-all duration-300`}
                    >
                      Assign
                    </Link>
                  )}
                  <Modal
                    open={openModal}
                    onClose={() => setOpen(false)}
                    header
                    headingText="Assign Account"
                  >
                    <div
                      className="max-h-[300px] overflow-y-auto"
                      id={"scrollableDiv"}
                    >
                      <div className="px-6 pb-6">
                        <div className="grid grid-cols-12">
                          {Object.keys(roleUserMap).map((key) => (
                            <div key={key} className="col-span-12 mb-6">
                              <FormLabel>Select {key}</FormLabel>
                              <SelectType
                                options={roleUserMap[key]?.map((item) => ({
                                  label: `${item.username}`,
                                  value: item.id,
                                }))}
                                fullWidth
                                placeHolder={`select ${key}`}
                                onChange={(e) =>
                                  handleSelectChange(key, e.value)
                                }
                              />
                            </div>
                          ))}
                          <div className="col-span-12">
                            <div className="flex items-center justify-center gap-4">
                              <Button
                                secondary
                                onClick={() => setOpen(false)}
                                className={`w-full`}
                              >
                                Cancel
                              </Button>
                              <Button
                                className={`w-full`}
                                primary={
                                  Object.values(selectedValues).length > 0
                                }
                                disabled={
                                  Object.values(selectedValues).length === 0
                                }
                                onClick={handleAsign}
                              >
                                Assign
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </Paragraph>
              </div>
              <div className="xl:col-span-9 col-span-12">
                <div className="flex items-center xl:justify-end justify-start lg:gap-4 gap-3">
                  <InputType
                    className="w-[200px]"
                    tableSearch
                    type="search"
                    placeholder="Search"
                    value={searchTerm ? searchTerm : ""}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                  <SelectType
                    fullWidth
                    tableSelect
                    placeHolder="Priority"
                    options={Priority}
                    value={
                      Priority?.find((opt) => opt?.value === priority) || ""
                    }
                    onChange={(e) => {
                      setpriority(e.value);
                    }}
                  />
                  <SelectType
                    className="!min-w-[115px]"
                    fullWidth
                    tableSelect
                    placeHolder="Overdue"
                    options={Overdue}
                    value={Overdue?.find((opt) => opt?.value === overdue) || ""}
                    onChange={(e) => setOverdue(e.value)}
                  />
                  <div className="">
                    <DatePicker
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                    />
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={resetFilter}
                      className="flex gap-1 items-center px-2 cursor-pointer rounded border border-light-grey-200 py-[8px] text-[16px] leading-[21px] font-normal w-full focus:outline-none text-dark-grey"
                    >
                      Reset <IoClose className="mt-0.5" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <TableLayout
                  tableHeader={tableHeader}
                  handleSorting={handleSorting}
                  currentItems={currentItems}
                  pageCount={pageCount}
                  itemOffset={itemOffset}
                  itemsPerPage={itemsPerPage}
                  handlePageClick={handlePageClick}
                  isBulkActive={userType?.is_admin_access}
                  handleAllBulk={handleAllBulk}
                  handleBulk={handleBulk}
                  selectedIds={selectedIds}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
