 

function WhiteCard({ children }) {
  return (
    <div className="p-4 rounded-lg bg-white border border-extra-light-blue">
      {children}
    </div>
  );
}

export default WhiteCard;
