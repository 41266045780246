 

function HeadingSix({ children, className }) {
  return (
    <h6
      className={`text-[14px] leading-[18px] font-normal ${
        className ? className : ""
      }`}
    >
      {children}
    </h6>
  );
}

export default HeadingSix;
