import React from "react";

const NotificationItem = ({ title, description }) => {
  return (
    <div className="py-2 px-6 bg-gray-100 border-l-4 border-blue-500 rounded-lg shadow-md max-w-md mt-2">
      <h1 className="text-lg font-bold text-gray-800 ">{title}</h1>
      <p className=" text-sm text-gray-600">{description}</p>
    </div>
  );
};

export default NotificationItem;
