/* eslint-disable react/prop-types */
import { useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { HiMiniSquares2X2 } from "react-icons/hi2";
import { FaUserGroup } from "react-icons/fa6";
import { IoPower } from "react-icons/io5";
import Modal from "../core/Modal";
import Paragraph from "../core/typography/Paragraph";
import Button from "../core/form-components/Button";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
  permissionsSelector,
  refreshTokenSelector,
  setRefreshToken,
  setToken,
  tokenSelector,
} from "../../redux/slices/userSlice";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { LogoutApi } from "../../service/authService";

const navItems = [
  {
    path: "/dashboard",
    icon: <HiMiniSquares2X2 fontSize="24px" />,
    permission: null, // Accessible to all
  },
  {
    path: "/usermanagement",
    icon: <FaUserGroup fontSize="24px" />,
    permission: "is_admin_access", // Requires admin access
  },
];

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector(permissionsSelector);
  const accessToken = useSelector(tokenSelector);
  const refreshToken = useSelector(refreshTokenSelector);
  const [open, setOpen] = useState(false);

  // Filter navItems based on permissions
  const accessibleNavItems = useMemo(
    () =>
      navItems.filter(
        (item) => !item.permission || permissions?.includes(item.permission)
      ),
    [permissions]
  );

  const handleLogout = async () => {
    dispatch(showLoader());
    const response = await LogoutApi({
      access_token: accessToken,
      refresh_token: refreshToken,
    });
    if (response?.data?.status === 200 && response?.data?.success) {
      dispatch(setToken(null));
      dispatch(setRefreshToken(null));
      navigate("/login");
    }
    dispatch(hideLoader());
  };

  return (
    <div className="lg:h-[100vh] overflow-y-auto relative">
      <div className="flex flex-col items-start gap-10 lg:pt-[90px] pt-0 lg:pb-0 pb-6">
        <ul
          className={`flex flex-col items-start lg:justify-center justify-start lg:w-full lg:static fixed top-0 transition-all duration-300 ease-in-out z-40 left-0 sm:w-1/2 w-full h-[calc(100vh-90px)]`}
        >
          {accessibleNavItems.map((item, index) => (
            <NavItem key={index} path={item.path} icon={item.icon} />
          ))}
          <li className="mb-[18px] mt-auto block w-full px-2 relative">
            <button
              onClick={() => setOpen(true)}
              className="inline-flex items-center justify-center xl:px-5 px-3.5 text-light-grey focus:text-primary-blue hover:text-primary-blue border-0"
            >
              <IoPower fontSize="24px" />
            </button>
          </li>
        </ul>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="p-[30px]">
          <span className="flex items-center justify-center mb-6">
            <TbAlertTriangleFilled
              fontSize={"50px"}
              className="text-light-red"
            />
          </span>
          <Paragraph className="mb-30 text-center text-[20px] leading-[26px] font-medium">
            Are you sure you want to logout?
          </Paragraph>
          <div className="flex items-center justify-center gap-3">
            <Button onClick={handleLogout} primary className="w-full">
              Yes
            </Button>
            <Button secondary onClick={() => setOpen(false)} className="w-full">
              No
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Sidebar;

function NavItem({ path, icon }) {
  return (
    <li className={`mb-[18px] block w-full px-2 relative`}>
      <NavLink
        to={path}
        className={({ isActive }) =>
          `inline-flex items-center justify-center xl:px-5 px-3.5 py-3.5 ${
            isActive ? "text-primary-blue" : "text-light-grey"
          }`
        }
      >
        {icon}
      </NavLink>
    </li>
  );
}
