import { apiEndPoints } from "../apiEndPoints";
import { axiosPost } from "../axios/axiosMiddleware";

export const LoginUser = (data) => {
  return axiosPost(apiEndPoints?.AUTH?.LOGIN, data);
};

export const ForgotPasswordApi = (data) => {
  return axiosPost(apiEndPoints?.AUTH?.FORGOT, data);
};

export const ResetPasswordApi = (data) => {
  return axiosPost(apiEndPoints?.AUTH?.RESET_PASSWORD, data);
};

export const LogoutApi = (data) => {
  return axiosPost(apiEndPoints?.AUTH?.LOGOUT, data);
};
