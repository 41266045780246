import DashboardScreen from "../../assets/images/login_dashboard_image.webp";
import Paragraph from "../core/typography/Paragraph";

function AuthLeftWrapper() {
  return (
    <div className="xl:p-15 p-10 rounded-xl flex flex-col items-start justify-start h-[calc(100vh-60px)] relative overflow-hidden z-0 bg-login-bg bg-no-repeat bg-[left_0_bottom_0] bg-cover">
      <Paragraph
        className={
          "text-white text-[44px] leading-[57px] font-normal w-full max-w-[494px]"
        }
      >
        Streamline Your Workflow with{" "}
        <span className="font-bold block">AR Task Automation</span> Simplify,
        Optimize, Succeed!
      </Paragraph>
      <div className="relative bottom-0 -right-[60px] self-end max-h-[579px]">
        <img
          src={DashboardScreen}
          alt="dashboard_mockup"
          width="620px"
          height="582px"
          className="inline-block"
        />
      </div>
    </div>
  );
}

export default AuthLeftWrapper;
