/* eslint-disable react/prop-types */
import { useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { capitalizeFunction } from "../utils/commonHelper";
import { RxArrowLeft, RxArrowRight } from "react-icons/rx";
import Paragraph from "./core/typography/Paragraph";

const TableLayout = (props) => {
  const {
    tableHeader,
    handleSorting,
    currentItems,
    pageCount,
    itemOffset,
    itemsPerPage,
    handlePageClick,
    isBulkActive,
    handleAllBulk,
    handleBulk,
    selectedIds,
  } = props;

  const dropdownRef = useRef(null);
  const [sortOrders, setSortOrders] = useState({});
  const [lastSortedColumn, setLastSortedColumn] = useState(null);

  const handleSortTable = (sortKey, initialOrder) => {
    const currentOrder = sortOrders[sortKey];
    const newSortOrder =
      currentOrder === undefined ? initialOrder : !currentOrder;
    const newSortOrders = { [sortKey]: newSortOrder };
    setSortOrders(newSortOrders);
    setLastSortedColumn(sortKey);
    handleSorting?.(sortKey, newSortOrder);
  };

  const isFirstPage = itemOffset == 0;
  const isLastPage = Math.ceil(itemOffset / itemsPerPage) === pageCount - 1;

  //Note: Do not remove
  // const handeldataShow = (dataItem, item) => {
  //   // Handle nested data
  //   if (item?.value?.includes(".")) {
  //     const keys = item?.value?.split(".");
  //     let nestedValue = dataItem;
  //     for (const key of keys) {
  //       nestedValue = nestedValue[key];
  //       if (nestedValue === undefined) break;
  //     }
  //     return nestedValue || "--";
  //   } else {
  //     // Handle flat data
  //     return dataItem?.[item.value] || "--";
  //   }
  // };

  const getNestedValue = (object, path, defaultValue = "--") => {
    if (!path) return defaultValue;
    const keys = path.split(".");
    let value = object;
    for (const key of keys) {
      value = value?.[key];
      if (value === undefined) return defaultValue;
    }
    return value || defaultValue;
  };

  const handeldataShow = (dataItem, item) => {
    const { value } = item || {};
    return getNestedValue(dataItem, value);
  };

  return (
    <div className="table-wrapper">
      <div className="relative rounded-lg border border-extra-light-blue overflow-y-hidden table-scroll">
        <table className="w-full text-sm leading-[18px] font-normal text-center text-site-black whitespace-nowrap">
          <thead className="text-[14px] leading-[18px] font-normal text-dark-grey bg-light-grey-bg">
            <tr className="border-b border-extra-light-blue">
              {isBulkActive && (
                <td
                  key="bulk"
                  className="p-4 text-base font-light text-gray-500 whitespace-nowrap"
                >
                  <input
                    className="w-4 h-4"
                    type="checkbox"
                    id={`checkbox-bulk`}
                    onChange={() => handleAllBulk(currentItems)}
                    checked={
                      selectedIds?.length > 0 &&
                      selectedIds?.length === currentItems?.length
                    }
                  />
                </td>
              )}
              {tableHeader?.map((item, key) => (
                <th
                  scope="col"
                  className={`p-4 ${
                    key === 0 || item?.textLeft ? "text-start" : "text-center"
                  } text-[14px] leading-[18px] font-normal`}
                  key={key}
                >
                  {item?.sorting && item.sortkey ? (
                    <span className="flex items-center justify-start gap-2">
                      <div>
                        <span>{capitalizeFunction(item?.key)}</span>
                      </div>
                      <span className="inline-flex flex-col gap-[2px] sort-icons">
                        <span
                          className="sort-icon"
                          onClick={() =>
                            handleSortTable(item.sortkey || "", true)
                          }
                        >
                          <svg
                            width="8"
                            height="6"
                            viewBox="0 0 8 6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill={
                              sortOrders[item.sortkey] &&
                              lastSortedColumn === item.sortkey
                                ? "#4880ff" // Active color
                                : "#606060" // Inactive color
                            }
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7.12513 5.25C7.24873 5.24997 7.36954 5.21225 7.47229 5.14161C7.57505 5.07097 7.65514 4.97058 7.70243 4.85312C7.74973 4.73567 7.7621 4.60643 7.738 4.48174C7.71389 4.35705 7.65439 4.24252 7.56701 4.15261L4.44201 0.938226C4.3248 0.817705 4.16586 0.75 4.00013 0.75C3.83441 0.75 3.67546 0.817705 3.55826 0.938226L0.433257 4.15261C0.345876 4.24252 0.286373 4.35705 0.262268 4.48174C0.238163 4.60643 0.25054 4.73567 0.297834 4.85312C0.345128 4.97058 0.425215 5.07097 0.527971 5.14161C0.630727 5.21225 0.751538 5.24997 0.875132 5.25L7.12513 5.25Z"
                            />
                          </svg>
                        </span>
                        <span
                          className="sort-icon"
                          onClick={() =>
                            handleSortTable(item.sortkey || "", false)
                          }
                        >
                          <svg
                            width="8"
                            height="5"
                            viewBox="0 0 8 5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill={
                              !sortOrders[item.sortkey] &&
                              lastSortedColumn === item.sortkey
                                ? "#4880ff" // Active color
                                : "#606060" // Inactive color
                            }
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.874867 0.25C0.751273 0.250027 0.630462 0.287746 0.527705 0.358388C0.424949 0.42903 0.344862 0.529424 0.297568 0.646877C0.250274 0.764331 0.237897 0.89357 0.262002 1.01826C0.286107 1.14295 0.345611 1.25748 0.432992 1.34739L3.55799 4.56177C3.6752 4.68229 3.83414 4.75 3.99987 4.75C4.16559 4.75 4.32454 4.68229 4.44174 4.56177L7.56674 1.34739C7.65412 1.25748 7.71363 1.14295 7.73773 1.01826C7.76184 0.89357 7.74946 0.764331 7.70217 0.646877C7.65487 0.529424 7.57479 0.42903 7.47203 0.358388C7.36927 0.287746 7.24846 0.250027 7.12487 0.25H0.874867Z"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                  ) : (
                    capitalizeFunction(item?.key)
                  )}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentItems?.length > 0 ? (
              currentItems.map((dataItem, dataKey) => (
                <tr
                  className="bg-white border-b border-extra-light-blue"
                  key={dataKey}
                >
                  {isBulkActive && (
                    <td
                      key={dataKey}
                      className="p-4 text-base font-light text-gray-500 whitespace-nowrap"
                    >
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        id={`checkbox-${dataKey}`}
                        onChange={() => handleBulk(dataItem)}
                        checked={selectedIds?.includes(dataItem?.id)}
                      />
                    </td>
                  )}
                  {tableHeader?.map((item, key) => (
                    <td
                      className={`p-4 text-left ${item.clickable ? "cursor-pointer" : ""}`}
                      key={key}
                      ref={dropdownRef}
                    >
                      {item.cell
                        ? item.cell(dataItem)
                        : handeldataShow(dataItem, item)}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr className="">
                <td colSpan={tableHeader?.length} className="text-center py-4">
                  <Paragraph text18>No Data Found!</Paragraph>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-end items-center mt-4 mb-0">
        {pageCount > 1 && (
          <ReactPaginate
            breakLabel="..."
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={
              <span
                className={`h-[36px] w-[36px] flex justify-center items-center px-[8px] ${isFirstPage ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
              >
                <RxArrowLeft fontSize={"20px"} />
              </span>
            }
            nextLabel={
              <span
                className={`h-[36px] w-[36px] flex justify-center items-center px-[8px] ${isLastPage ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
              >
                <RxArrowRight fontSize={"20px"} />
              </span>
            }
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={"pageItem"}
            forcePage={itemOffset / itemsPerPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageLinkClassName="page-link"
            previousClassName={`page-item ${pageCount === 1 ? "disabled" : ""}`}
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        )}
      </div>
    </div>
  );
};

export default TableLayout;
