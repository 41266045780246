/* eslint-disable react/prop-types */
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { permissionsSelector } from "../redux/slices/userSlice";
import { paths } from "./path";

const ProtectedRoute = ({ element: Component, permissions }) => {
  const userPermissions = useSelector(permissionsSelector);

  const hasPermission =
    !permissions ||
    permissions?.every((perm) => userPermissions?.includes(perm));

  return hasPermission ? (
    <Component />
  ) : (
    <Navigate to={paths?.web?.unauthorized} replace />
  );
};

export default ProtectedRoute;
