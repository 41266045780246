import * as Yup from "yup";

export const addUserValidationSchema = () =>
  Yup.object().shape({
    username: Yup.string()
      .trim()
      .matches(/^(?!\d+$)[\w\s]+$/, "Username cannot be only digits")

      .max(255, "Maximum 255 Characters allowed")
      .min(3, "Minimum 3 characters allowed")
      .required("Full name is required"),
    email: Yup.string()
      .email("Please enter valid email")
      .trim()
      .max(255, "Maximum 255 Characters allowed")
      .required("Email is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "Email is invalid"),
    roles: Yup.string().required("Role is required"),
  });

export const addRoleValidationSchema = () =>
  Yup.object().shape({
    roles: Yup.string()
      .matches(/^(?!\d+$)[\w\s]+$/, "Role cannot be only digits")

      .max(100, "Maximum 100 Characters allowed")
      .min(1, "Minimum 1 Character required")
      .required("Role is required"),
  });
