/* eslint-disable react/prop-types */
// src/routes/GuestRoute.jsx

import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { tokenSelector } from "../redux/slices/userSlice";
import { paths } from "./path";

const GuestRoute = ({ children }) => {
  const token = useSelector(tokenSelector);
  const location = useLocation();

  if (token) {
    return <Navigate to={paths.web.dashboard} state={{ from: location }} />;
  }

  return children;
};

export default GuestRoute;
