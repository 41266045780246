/** @format */

import axios from "axios";
import { ToastShow } from "../redux/slices/toastSlice";
import { clearUser, setToken } from "../redux/slices/userSlice";
import { apiEndPoints } from "../apiEndPoints";
import { paths } from "../constants/pathConstants";
import { VITE_APP_API_URL } from "../config";

const setupAxios = (store) => {
  axios.interceptors.request.use((request) => {
    const { token } = store.getState().user;
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  });

  axios.interceptors.response.use(
    (res) => {
      const { message } = res?.data || {};
      if (message && res.config.method !== "get") {
        store.dispatch(ToastShow({ message, type: "success" }));
      }
      return res;
    },
    async (error) => {
      const storeData = store.getState();
      const originalRequest = error?.config;

      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const { refreshToken } = storeData.user;

        try {
          const { data: responseData, status } = await axios.post(
            `${VITE_APP_API_URL}${apiEndPoints.AUTH.REFRESH}`,
            { refresh: refreshToken }
          );

          if (responseData?.access && status === 200) {
            store.dispatch(setToken(responseData.access));
            axios.defaults.headers.common.Authorization = `Bearer ${responseData.access}`;
            return axios(originalRequest); // Retry original request with new token
          }
        } catch (refreshError) {
          handleAuthError(refreshError, store);
        }
      } else {
        handleErrorMessage(error, store);
        return;
      }

      return Promise.reject(error);
    }
  );
};

const handleAuthError = (error, store) => {
  if (axios.isAxiosError(error) && error.response?.status === 401) {
    store.dispatch(clearUser());
    window.location.href = paths.auth.login;
  } else {
    handleErrorMessage(error, store);
  }
};

const handleErrorMessage = (error, store) => {
  const message = `${Object?.values(error?.response?.data?.errors?.[0] || {})[0] || "An error occurred."}`;
  store.dispatch(ToastShow({ message, type: "error" }));
};

export default setupAxios;

export const axiosGet = (url, data = null) =>
  axios.get(`${VITE_APP_API_URL}${url}`, { params: data });

export const axiosPost = (url, data, headers) =>
  axios.post(`${VITE_APP_API_URL}${url}`, data, headers || {});

export const axiosConfig = (method, url, config, data) =>
  axios({ method, url: `${VITE_APP_API_URL}${url}`, ...config, data });

export const axiosPatch = (url, data) =>
  axios.patch(`${VITE_APP_API_URL}${url}`, data);

export const axiosPut = (url, data) =>
  axios.put(`${VITE_APP_API_URL}${url}`, data);

export const axiosDelete = (url, data) =>
  axios.delete(`${VITE_APP_API_URL}${url}`, { data });
