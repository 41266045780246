// src/routes/SecurityCheck.jsx
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { clearUser, tokenSelector } from "../redux/slices/userSlice";
import { paths } from "./path";

function SecurityCheck() {
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      dispatch(clearUser());
    }
  }, [token]);

  if (!token) {
    return (
      <Navigate to={paths.auth.login} state={{ from: location }} replace />
    );
  }

  return <Outlet />;
}

export default SecurityCheck;
