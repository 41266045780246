/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Paragraph from "../components/core/typography/Paragraph";
import TableLayout from "../components/TableLayout";
import Button from "../components/core/form-components/Button";
import RadioButton from "../components/core/form-components/RadioButton";
import FormLabel from "../components/core/typography/FormLabel";
import InputType from "../components/core/form-components/InputType";
import SelectType from "../components/core/form-components/SelectType";
import Checkbox from "../components/core/form-components/Checkbox";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../redux/slices/siteLoaderSlice";
import { Formik, Form } from "formik";
import {
  AddRole,
  AddUser,
  EditRoleData,
  EditUserData,
  GetAllRoleList,
  GetUserList,
} from "../service/userManagementService";
import {
  addRoleValidationSchema,
  addUserValidationSchema,
} from "../validations/user/addUserValidationSchema";

function UserManagement() {
  const [selectedRadio, setSelectedRadio] = useState("addUser");
  const [roleList, setRoleList] = useState([]);
  const [defaultRoleValues, setDefaultRoleValues] = useState({
    roles: "",
    assign_admin: false,
    assign_all: false,
    visiblepayment: false,
  });

  const [defaultUserValues, setDefaultUserValues] = useState({
    username: "",
    email: "",
    roles: "",
  });

  const itemsPerPage = 10;
  const [orderby, setOrderby] = useState("");
  const [order, setOrder] = useState(false);
  const dispatch = useDispatch();

  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  const [isEditUser, setIsEditUser] = useState(false);
  const [isEditRole, setIsEditRole] = useState(false);

  useEffect(() => {
    fetchAllRoles();
  }, []);

  useEffect(() => {
    getUserData(itemOffset / itemsPerPage + 1);
  }, [orderby, order, itemOffset, defaultRoleValues, defaultUserValues]);

  const tableHeader = [
    {
      key: "User Name",
      value: "username",
      sorting: true,
      sortkey: "username",
      textLeft: true,
      cell: (user) => (
        <div className="cursor-pointer" onClick={() => handleEditUser(user)}>
          {user?.username ?? "-"}
        </div>
      ),
    },
    {
      key: "Email",
      value: "email",
      sorting: true,
      sortkey: "email",
      textLeft: true,
    },
    {
      key: "Role",
      value: "user_type.name",
      sorting: true,
      sortkey: "user_type__name",
      textLeft: true,
      cell: ({ user_type }) => (
        <div
          className="cursor-pointer"
          onClick={() => handleEditRole(user_type)}
        >
          {user_type
            ? user_type?.name.length > 8
              ? user_type?.name.slice(0, 8) + "..."
              : user_type?.name
            : "-"}
        </div>
      ),
    },
    {
      key: "Is admin",
      value: "user_type.is_admin_access",
      sorting: true,
      sortkey: "user_type__is_admin_access",
      textLeft: true,
      cell: ({ user_type }) => <>{user_type?.is_admin_access ? "Yes" : "No"}</>,
    },
    {
      key: "Payment visible",
      value: "user_type.is_amount_visible",
      sorting: true,
      sortkey: "user_type__is_amount_visible",
      textLeft: true,
      cell: ({ user_type }) => (
        <>{user_type?.is_amount_visible ? "Yes" : "No"}</>
      ),
    },
  ];

  const handleEditUser = (item) => {
    setIsEditUser(true);
    setIsEditRole(false);
    setSelectedRadio("addUser");
    setDefaultUserValues({
      ...item,
      username: item.username,
      email: item.email,
      roles: item.user_type?.id,
    });
    setDefaultRoleValues({
      roles: "",
      assign_admin: false,
      assign_all: false,
      visiblepayment: false,
    });
  };

  const handleEditRole = (item) => {
    setIsEditUser(false);
    setIsEditRole(true);
    setSelectedRadio("addRole");
    setDefaultUserValues({
      username: "",
      email: "",
      roles: "",
    });
    setDefaultRoleValues({
      ...item,
      roles: item?.name,
      assign_admin: item?.is_admin_access,
      assign_all: item?.is_all_invoice_access,
      visiblepayment: item?.is_amount_visible,
    });
  };

  const fetchAllRoles = async () => {
    try {
      const response = await GetAllRoleList();
      if (response?.status === 200) {
        const formattedData = response.data.data.map((role) => ({
          label: role.name,
          value: role.id,
        }));
        setRoleList(formattedData);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const OnSubmitAddRole = async (values, { resetForm }) => {
    dispatch(showLoader());
    const dataaddrole = {
      name: values.roles,
      is_amount_visible: values.visiblepayment,
      is_admin_access: values.assign_admin,
      is_all_invoice_access: values.assign_all,
    };
    try {
      if (isEditRole) {
        const response = await EditRoleData(values?.id, dataaddrole);
        if (response?.status === 200) {
          setDefaultRoleValues({
            roles: "",
            assign_admin: false,
            assign_all: false,
            visiblepayment: false,
          });
          setIsEditRole(false);
          getUserData(1);
          setItemOffset(0);
          fetchAllRoles();
        }
      } else {
        const response = await AddRole(dataaddrole);
        if (response?.status === 201) {
          resetForm();
          getUserData(1);
          setItemOffset(0);
          fetchAllRoles();
        }
      }
    } catch (error) {
      console.error("Error adding role:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const OnSubmitAddUser = async (values, { resetForm }) => {
    dispatch(showLoader());
    try {
      if (isEditUser) {
        const dataAdduser = {
          username: values.username,
          user_type: values.roles,
        };
        const response = await EditUserData(values?.id, dataAdduser);
        if (response?.status === 200) {
          setDefaultUserValues({
            username: "",
            email: "",
            roles: "",
          });
          setIsEditUser(false);
          getUserData(1);
          setItemOffset(0);
        }
      } else {
        const dataAdduser = {
          username: values.username,
          user_type: values.roles,
          email: values.email,
        };
        const response = await AddUser(dataAdduser);
        if (response?.status === 200) {
          resetForm();
          getUserData(1);
          setItemOffset(0);
        }
      }
    } catch (error) {
      console.error("Error adding user:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handlePageClick = (event) =>
    setItemOffset(event.selected * itemsPerPage);

  const handleSorting = (data, order) => {
    setOrderby(data);
    setOrder(order);
    setItemOffset(0);
  };

  const getUserData = async (page) => {
    dispatch(showLoader());
    try {
      let queryString = `?page=${page}`;
      if (orderby) {
        const orderDirection = order ? "" : "-";
        queryString += `&ordering=${orderDirection}${orderby}`;
      }
      const response = await GetUserList(queryString);
      if (response?.status === 200) {
        setCurrentItems(response.data.data.results || []);
        setPageCount(Math.ceil(response.data.data.count / itemsPerPage));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.id);
    if (event.target.id === "addUser") {
      setDefaultRoleValues({
        roles: "",
        assign_admin: false,
        assign_all: false,
        visiblepayment: false,
      });
    } else {
      setDefaultUserValues({
        username: "",
        email: "",
        roles: "",
      });
    }
  };

  function getActionLabel(selectedRadio) {
    if (isEditUser && selectedRadio === "addUser") {
      return "Edit User";
    } else if (isEditRole && selectedRadio === "addRole") {
      return "Edit Role";
    } else {
      return selectedRadio === "addUser" ? "Add User" : "Add Role";
    }
  }

  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div className="flex items-center justify-between pb-4">
            <Paragraph text32>User Management</Paragraph>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 sm:gap-4 gap-3">
        <div className="col-span-8">
          <div className="bg-white rounded-xl xl:p-30 p-4">
            <div className="xl:pb-4 pb-3">
              <Paragraph text20>Users</Paragraph>
            </div>
            <TableLayout
              tableHeader={tableHeader}
              handleSorting={handleSorting}
              currentItems={currentItems}
              pageCount={pageCount}
              itemOffset={itemOffset}
              itemsPerPage={itemsPerPage}
              handlePageClick={handlePageClick}
            />
          </div>
        </div>
        <div className="col-span-4">
          <div className="bg-white rounded-xl">
            <div className="xl:pt-6 pt-4 xl:px-6 px-4 xl:pb-4 pb-3 border-b border-extra-light-blue">
              <Paragraph text20>{getActionLabel(selectedRadio)}</Paragraph>
            </div>
            <div className="xl:p-6 p-4">
              <div className="flex items-center xl:gap-[30px] gap-4 mb-4">
                <RadioButton
                  name="add_user_role"
                  id="addUser"
                  checked={selectedRadio === "addUser"}
                  onChange={handleRadioChange}
                  className={`font-semibold`}
                >
                  User
                </RadioButton>
                <RadioButton
                  name="add_user_role"
                  id="addRole"
                  checked={selectedRadio === "addRole"}
                  onChange={handleRadioChange}
                  className={`font-semibold`}
                >
                  Role
                </RadioButton>
              </div>
              {selectedRadio === "addUser" && (
                <Formik
                  enableReinitialize
                  initialValues={defaultUserValues}
                  validationSchema={addUserValidationSchema}
                  onSubmit={OnSubmitAddUser}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleReset,
                  }) => (
                    <Form>
                      <div className="grid grid-cols-12">
                        <div className="col-span-full form-control relative mb-4">
                          <FormLabel>Full Name</FormLabel>
                          <InputType
                            type="text"
                            placeholder="Enter full name"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            error={
                              errors.username &&
                              touched.username &&
                              errors.username
                            }
                          />
                        </div>
                        <div className="col-span-full form-control relative mb-4">
                          <FormLabel>Email</FormLabel>
                          <InputType
                            type="text"
                            placeholder="Enter email address"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            error={
                              errors.email && touched.email && errors.email
                            }
                            disabled={isEditUser}
                          />
                        </div>
                        <div className="col-span-full form-control relative xl:mb-30 mb-4">
                          <FormLabel>Roles</FormLabel>
                          <SelectType
                            fullWidth
                            options={roleList}
                            placeHolder="Select Role"
                            isSearchable={false}
                            error={errors.roles}
                            onChange={(option) =>
                              setFieldValue("roles", option?.value)
                            }
                            value={
                              roleList?.find(
                                (option) => option.value === values.roles
                              ) || ""
                            }
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-center gap-4">
                        <Button
                          secondary
                          type="button"
                          className="w-full"
                          onClick={() => {
                            console.log("cancel clicked");
                            setDefaultUserValues({
                              username: "",
                              email: "",
                              roles: "",
                            });
                            handleReset();

                            if (isEditUser) {
                              setIsEditUser(false);
                            }
                          }}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" primary className="w-full">
                          Save
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
              {selectedRadio === "addRole" && (
                <Formik
                  enableReinitialize
                  initialValues={defaultRoleValues}
                  validationSchema={addRoleValidationSchema}
                  onSubmit={OnSubmitAddRole}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleReset,
                  }) => (
                    <Form>
                      <div className="grid grid-cols-12">
                        <div className="col-span-full form-control relative mb-4">
                          <FormLabel>Roles</FormLabel>
                          <InputType
                            type="text"
                            placeholder="Enter role"
                            name="roles"
                            value={values.roles}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.roles && touched.roles && errors.roles
                            }
                          />
                        </div>
                        <div className="col-span-full form-control relative mb-4">
                          <FormLabel>Is the payment amount visible?</FormLabel>
                          <div
                            className="flex items-center xl:gap-6 gap-4"
                            role="group"
                          >
                            <RadioButton
                              checked={values.visiblepayment}
                              name="payment"
                              id="paymentYes"
                              onChange={() =>
                                setFieldValue("visiblepayment", true)
                              }
                            >
                              Yes
                            </RadioButton>
                            <RadioButton
                              checked={!values.visiblepayment}
                              name="payment"
                              id="paymentNo"
                              onChange={() =>
                                setFieldValue("visiblepayment", false)
                              }
                            >
                              No
                            </RadioButton>
                          </div>
                        </div>
                        <div className="col-span-full form-control relative mb-4">
                          <Checkbox
                            w16
                            name="assign_admin"
                            id="assignAdmin"
                            checked={values.assign_admin}
                            onChange={() => {
                              setFieldValue(
                                "assign_admin",
                                !values.assign_admin
                              );
                              setFieldValue("assign_all", !values.assign_admin);
                            }}
                          >
                            Assign admin access
                          </Checkbox>
                        </div>
                        <div className="col-span-full form-control relative xl:mb-30 mb-4">
                          <Checkbox
                            w16
                            name="assign_all"
                            id="assignAll"
                            checked={values.assign_all}
                            onChange={() => {
                              if (!values.assign_admin)
                                setFieldValue("assign_all", !values.assign_all);
                            }}
                          >
                            Assign all invoices
                          </Checkbox>
                        </div>
                      </div>
                      <div className="flex items-center justify-center gap-4">
                        <Button
                          secondary
                          type="button"
                          className="w-full"
                          onClick={() => {
                            // setDefaultRoleValues({
                            //   roles: "",
                            //   assign_admin: false,
                            //   assign_all: false,
                            //   visiblepayment: false,
                            // });
                            handleReset();
                            // if (isEditRole) {
                            //   setIsEditRole(false);
                            // }
                          }}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" primary className="w-full">
                          Save
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
