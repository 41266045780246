import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'
import userReducer from './slices/userSlice'
import toastReducer from './slices/toastSlice'
import siteLoaderReducer from './slices/siteLoaderSlice'


const persistConfig = {
  key: 'Ar-task',
  storage: sessionStorage,
  whitelist: ['user'],
}

const rootReducer = combineReducers({
  user: userReducer,
  toast: toastReducer,
  siteLoader: siteLoaderReducer,
})

export default persistReducer(persistConfig, rootReducer)
