import { apiEndPoints } from "../apiEndPoints";
import { axiosPatch, axiosPost } from "../axios/axiosMiddleware";
import { axiosGet } from "../axios/axiosMiddleware";

export const AddUser = (data) => {
  return axiosPost(apiEndPoints?.USER?.ADDUSER, data);
};
export const AddRole = (data) => {
  return axiosPost(apiEndPoints?.USER?.ADDROLE, data);
};
export const GetUserList = (queryString) => {
  return axiosGet(`${apiEndPoints?.USER?.GETUSER}${queryString}`);
};

export const GetAllRoleList = () => {
  return axiosGet(`${apiEndPoints?.USER?.ROLELIST}`);
};

export const EditUserData = (id, data) => {
  return axiosPatch(`${apiEndPoints?.USER?.EDITUSER}/${id}/`, data);
};

export const EditRoleData = (id, data) => {
  return axiosPatch(`${apiEndPoints?.USER?.EDITROLE}/${id}/`, data);
};
