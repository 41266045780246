 

function HeadingOne({ children, className }) {
  return (
    <h1
      className={`md:text-[32px] md:leading-[46px] text-[28px] leading-[40px] font-semibold ${
        className ? className : ""
      }`}
    >
      {children}
    </h1>
  );
}

export default HeadingOne;
