 

function HeadingThree({ children, className }) {
  return (
    <h3
      className={`sm:text-[20px] text-[18px] leading-[26px] font-bold ${
        className ? className : ""
      }`}
    >
      {children}
    </h3>
  );
}

export default HeadingThree;
