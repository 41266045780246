/* eslint-disable no-unused-vars */
import AuthLeftWrapper from "../../components/containers/AuthLeftWrapper";
import Paragraph from "../../components/core/typography/Paragraph";
import FormLabel from "../../components/core/typography/FormLabel";
import InputType from "../../components/core/form-components/InputType";
import Button from "../../components/core/form-components/Button";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/sitelogo.png";
import { paths } from "../../routes/path";
import { useState } from "react";
import { loginValidationSchema } from "../../validations/authentication/loginValidationSchema";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { LoginUser } from "../../service/authService";
import {
  setRefreshToken,
  setToken,
  setUser,
  setUserPermission,
  setUserType,
} from "../../redux/slices/userSlice";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [defaultInitialValues] = useState({
    email: "",
    password: "",
  });

  async function OnSubmit(paramsData) {
    dispatch(showLoader());
    const params = {
      email: paramsData?.email,
      password: paramsData?.password,
    };
    try {
      const response = await LoginUser(params);
      if (response?.status === 200) {
        dispatch(setToken(response?.data?.data?.token?.access));
        dispatch(setRefreshToken(response?.data?.data?.token?.refresh));
        dispatch(setUser(response?.data?.data?.user));
        dispatch(setUserType(response?.data?.data?.user_type));
        const permissionsArray = Object.keys(
          response?.data?.data?.user_type
        ).filter(
          (key) => key.startsWith("is_") && response?.data?.data?.user_type[key]
        );
        dispatch(setUserPermission(permissionsArray));
        navigate(paths?.web?.dashboard);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  }

  return (
    <div className="bg-white p-30 min-h-screen">
      <div className="grid grid-cols-12 lg:gap-6 gap-3 h-full">
        <div className="col-span-6 h-full md:block hidden">
          <AuthLeftWrapper />
        </div>
        <div className="md:col-span-6 sm:col-span-8 col-span-12 md:col-start-7 sm:col-start-3">
          <div className="flex items-center flex-col h-full xl:pt-[120px] pt-[100px]">
            <div className="mb-15 block">
              <img src={Logo} alt="logo" width="268px" height="105px" />
            </div>
            <Formik
              initialValues={defaultInitialValues}
              validationSchema={loginValidationSchema}
              onSubmit={OnSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form className="xxl:w-3/4 xl:w-4/5 w-full h-full">
                  <div className="grid grid-cols-12">
                    <div className="col-span-12 mb-6">
                      <FormLabel>Email address</FormLabel>
                      <InputType
                        placeholder="Enter email address"
                        type="text"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={errors.email && touched.email && errors.email}
                      />
                    </div>
                    <div className="col-span-12 mb-[8px]">
                      <FormLabel>Password</FormLabel>
                      <InputType
                        placeholder="Enter password"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        error={
                          errors.password && touched.password && errors.password
                        }
                      />
                    </div>
                    <div className="col-span-12">
                      <div className="flex items-center justify-end gap-3 mb-30">
                        <Link
                          className="text-sm leading-[18px] font-normal text-dark-grey hover:text-primary-blue transition-all duration-300"
                          to={paths.auth?.forget_password}
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <Button
                        primary
                        className={"w-full lg:!py-3"}
                        type="submit"
                      >
                        Login
                      </Button>
                      {/* <Paragraph className={"text-center mt-4"}>
                        Don't have account?{" "}
                        <Link to={paths?.signup}>Register here</Link>.
                      </Paragraph> */}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="col-span-12 mt-4">
              <Paragraph text14 className={"text-dark-grey text-center"}>
                {`© Copyright ${new Date().getFullYear()}. Vrinsoft, All rights reserved.`}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
