/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from "react";

// Icon component
const Icon = ({ isOpen }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`transition-all ${isOpen ? "rotate-180" : ""}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83316 8C5.66836 8.00004 5.50728 8.04893 5.37027 8.1405C5.23327 8.23207 5.12648 8.36221 5.06342 8.51445C5.00037 8.6667 4.98386 8.83423 5.016 8.99586C5.04814 9.15749 5.12748 9.30596 5.24399 9.4225L9.41066 13.5892C9.56693 13.7454 9.77885 13.8332 9.99982 13.8332C10.2208 13.8332 10.4327 13.7454 10.589 13.5892L14.7557 9.4225C14.8722 9.30596 14.9515 9.15749 14.9836 8.99586C15.0158 8.83423 14.9993 8.6667 14.9362 8.51445C14.8732 8.36221 14.7664 8.23207 14.6294 8.1405C14.4924 8.04893 14.3313 8.00004 14.1665 8H5.83316Z"
        fill="#606060"
      />
    </svg>
  );
};

const IconSort = ({ isOpen }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.30806 13.808C3.55214 13.564 3.94786 13.564 4.19194 13.808L6.25 15.8661L8.30806 13.808C8.55212 13.564 8.94788 13.564 9.19194 13.808C9.436 14.0521 9.436 14.4479 9.19194 14.6919L6.69194 17.1919C6.44788 17.436 6.05214 17.436 5.80806 17.1919L3.30806 14.6919C3.06398 14.4479 3.06398 14.0521 3.30806 13.808Z"
        fill="#606060"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 3.625C6.59519 3.625 6.875 3.90483 6.875 4.25V16.75C6.875 17.0952 6.59519 17.375 6.25 17.375C5.90483 17.375 5.625 17.0952 5.625 16.75V4.25C5.625 3.90483 5.90483 3.625 6.25 3.625Z"
        fill="#606060"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.308 3.80806C13.5521 3.56398 13.9479 3.56398 14.1919 3.80806L16.6919 6.30806C16.936 6.55214 16.936 6.94787 16.6919 7.19194C16.4479 7.436 16.0521 7.436 15.808 7.19194L13.75 5.13388L11.6919 7.19194C11.4479 7.436 11.0521 7.436 10.808 7.19194C10.564 6.94787 10.564 6.55214 10.808 6.30806L13.308 3.80806Z"
        fill="#606060"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 3.625C14.0952 3.625 14.375 3.90483 14.375 4.25V16.75C14.375 17.0952 14.0952 17.375 13.75 17.375C13.4048 17.375 13.125 17.0952 13.125 16.75V4.25C13.125 3.90483 13.4048 3.625 13.75 3.625Z"
        fill="#606060"
      />
    </svg>
  );
};

// CloseIcon component
const CloseIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="14"
      height="14"
      stroke="#919297"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  );
};

// CustomSelect component
const SelectType = ({
  error,
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  fullWidth,
  tableSelect,
  tableSelectSort,
  value,
  className,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    value || (isMulti ? [] : placeHolder ? null : options[0])
  );
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef();
  const inputRef = useRef();
  const measureRef = useRef(null);
  const [selectWidth, setSelectWidth] = useState("auto");
  const [preventClose, setPreventClose] = useState(false);

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setTimeout(() => {
          setShowMenu(false);
        }, 200);
      }
    };

    window.addEventListener("mousedown", handler);
    return () => {
      window.removeEventListener("mousedown", handler);
    };
  }, [inputRef]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    const widths = options.map((option) => {
      measureRef.current.innerText = option.label;
      return measureRef.current.clientWidth;
    });
    const maxWidth = Math.max(...widths);
    setSelectWidth(maxWidth + 32);
  }, [options]);

  const handleInputClick = () => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder || options[0]?.label;
    }
    if (isMulti) {
      return (
        <div className="flex flex-wrap gap-1">
          {selectedValue.map((option, index) => (
            <div
              key={`${option.value}-${index}`}
              className={`bg-light-grey text-site-black font-normal text-xs px-1 rounded-md flex items-center gap-1 py-1`}
            >
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="flex item-center"
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return <div className="truncate">{selectedValue.label}</div>;
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.value !== option.value);
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option, e) => {
    e.stopPropagation();
    setPreventClose(true);
    let newValue;
    if (isMulti) {
      if (selectedValue?.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
    setTimeout(() => {
      if (!preventClose) {
        setShowMenu(false);
      }
    }, 0);
  };

  useEffect(() => {
    if (preventClose) {
      setPreventClose(false);
    }
  }, [showMenu]);

  const isSelected = (option) => {
    if (isMulti) {
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <>
      <div
        className={`${
          tableSelect ? "" : ""
        } border rounded border-light-grey-200 relative cursor-pointer bg-white ${
          tableSelectSort
            ? "xl:max-w-[116px] max-w-[140px] w-full"
            : tableSelect
              ? "w-auto"
              : fullWidth
                ? "!w-full"
                : "w-auto"
        } ${className ? className : ""} `}
        style={{ width: selectWidth }}
      >
        <div
          ref={inputRef}
          onClick={handleInputClick}
          className={` ${
            tableSelect ? "py-[8px] px-[12px]" : "pt-[11px] pb-[10px] px-[15px]"
          } flex items-center justify-between gap-[8px] ${className ? className : ""}`}
        >
          <div
            className={`font-normal truncate text-[16px] leading-[21px] ${
              (!selectedValue || selectedValue.length === 0) && tableSelect
                ? "placeholder text-dark-grey"
                : !selectedValue || selectedValue.length === 0
                  ? " placeholder text-light-grey"
                  : "placeholder text-site-black"
            }`}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {getDisplay()}
          </div>
          <div className="dropdown-tools">
            <div className="dropdown-tool">
              {tableSelectSort ? (
                <IconSort isOpen={showMenu} />
              ) : (
                <Icon isOpen={showMenu} />
              )}
            </div>
          </div>
        </div>

        {showMenu && (
          <div
            className={`${
              tableSelect
                ? "border-t-0 pt-0 pb-[7px] -translate-y-[5px]"
                : "-translate-y-[1px]"
            } w-[calc(100%+2px)] pt-3 pb-[11px] absolute -translate-x-[1px] border-light-grey-200 border rounded rounded-t-none overflow-auto bg-white z-[30] max-h-[150px] min-h-[50px]`}
          >
            {isSearchable && (
              <div className="px-2 pb-[8px]">
                <input
                  className="w-full border rounded-lg border-medium-grey py-[6px] ps-[48px] pe-3 focus:outline-0 placeholder:text-light-grey bg-search-icon bg-no-repeat bg-[left_16px_top_8px]"
                  onChange={onSearch}
                  value={searchValue}
                  ref={searchRef}
                  type="search"
                  placeholder="Search here"
                />
              </div>
            )}
            {getOptions()?.map((option, index, arr) => (
              <div
                onClick={(e) => onItemClick(option, e)}
                key={option.value}
                className={`${
                  tableSelect
                    ? `hover:light-blue-bg ${
                        index === arr.length - 1 ? "" : "border-b"
                      } border-extra-light-blue text-[14px] leading-[18px] hover:bg-light-blue-bg`
                    : "hover:bg-light-grey-bg border-0 text-[16px] leading-[21px]"
                } py-1.5 px-2.5 cursor-pointer transition-all duration-300 ease font-normal text-site-black ${
                  isSelected(option) && "bg-light-grey-bg text-site-black"
                }`}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
        <div
          ref={measureRef}
          className="absolute invisible whitespace-nowrap px-3 py-[6px] font-semibold text-sm"
        ></div>
      </div>
      {error && (
        <div className="text-site-red text-sm font-medium">{error}</div>
      )}
    </>
  );
};

export default SelectType;
