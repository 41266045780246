/* eslint-disable react/prop-types */
 

function FormLabel({ children, className, ...props }) {
  return (
    <label
      {...props}
      className={`text-[14px] leading-[18px] text-site-black font-normal block mb-1.5 ${
        className ? className : ""
      }`}
    >
      {children}
    </label>
  );
}

export default FormLabel;
